// Modal
$(window).on('load', function () {
    // Variables
    var $modal_close = $('[data-modal-action="close"]')

    // // Closing modal
    $modal_close.on('click', function () {
        hideModal();
    });

    // Display magazine
    var $magazine = $('.cal_component-magazine')
    $magazine.on('click', function () {
        var $file = $(this).data('file');
        openMagazine($file);
    });
});

function showModal() {
    var $modal = $('.magazine-modal')
    $modal.addClass('active');
    setTimeout(function () {
        $modal.addClass('show');
    }, 150)
}
function hideModal() {
    var $modal = $('.magazine-modal')
    $modal.removeClass('show');
    setTimeout(function () {
        $modal.removeClass('active');
    }, 150)
}
function openMagazine(file) {
    var magazine_options = {
        pdf: file,
        template: {
            html: '/assets/lib/flip-book-jquery/templates/default-book-view.html',
            styles: [
                '/assets/lib/flip-book-jquery/css/black-book-view.css'
            ],
            links: [{
                rel: 'stylesheet',
                href: '/assets/lib/flip-book-jquery/css/font-awesome.min.css'
            }],
            script: '/assets/lib/flip-book-jquery/js/default-book-view.js',
        },
        controlsProps: {
            scale: {
                default: 1,
            },
            actions: {
                cmdToc: {
                    enabled: false,
                },
                cmdPrint: {
                    enabled: false,
                },
                widSettings: {
                    enabled: false,
                },
                cmdSmartPan: {
                    enabled: false,
                },
                cmdSinglePage: {
                    enabled: false,
                },
                cmdSounds: {
                    enabled: false,
                },
                cmdStats: {
                    enabled: false,
                },
                cmdLightingUp: {
                    enabled: false,
                },
                cmdLightingDown: {
                    enabled: false,
                }
            }
        }
    }
    var display_magazine = $('.display-magazine').FlipBook(magazine_options)
}
